@tailwind base;
@tailwind components;
@tailwind utilities;

// Base
@import "root/root";

// Components
@import "components/datePicker";
@import "components/multirange";
@import "components/dropbox";
@import "components/ToastStyle";

// Pages