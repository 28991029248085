.slider {
    position: absolute;
    width: 200px;

    &__track {
        position: absolute;
        border-radius: 3px;
        height: 5px;
        background-color: #D1D5DB;
        width: 100%;
        z-index: 1;
    }

    &__range {
        position: absolute;
        border-radius: 3px;
        height: 5px;
        background-color: #FF9600;
        z-index: 2;
    }

    &__left-value {
        position: absolute;
        color: #6B7280;
        font-size: 12px;
        margin-top: 20px;
        left: 6px;
    }
    &__right-value{
        position: absolute;
        color: #6B7280;
        font-size: 12px;
        margin-top: 20px;
        right: -4px;
    }
}
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.thumb {

    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;

    &--zindex-3{
        z-index: 3;
    }
    &--zindex-4{
        z-index: 4;
    }
    &--zindex-5{
        z-index: 5;
    }
    &::-webkit-slider-thumb {
        background-color: white;
        border: 1px solid #E5E7EB;
        border-radius: 50%;
        box-shadow:  0 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        height: 32px;
        width: 32px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
}